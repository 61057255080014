<template>
	<div class="login-body">
		<div class="login-container">
			<div class="head">
				<div class="name">
					<div class="tips">idaas admin</div>
				</div>
			</div>
			<a-form
				ref="merchantRef"
				style="margin: 0 auto"
				layout="inline"
				:model="state.ruleForm"
				:label-col="state.labelCol"
				:wrapper-col="state.wrapperCol"
				@finish="submitForm"
			>
				<a-form-item
					name="merchantName"
					:rules="[
						{
							required: true,
							message: `请输入商户名称`,
							trigger: 'blur',
						},
					]"
					:label="`商户名称`"
				>
					<a-input v-model:value="state.ruleForm.merchantName" :placeholder="`请输入商户名称`" />
				</a-form-item>
				<a-form-item
					name="userName"
					:rules="[
						{
							required: true,
							message: `请输入账号名称`,
							trigger: 'blur',
						},
					]"
					:label="`账号名称`"
				>
					<a-input v-model:value="state.ruleForm.userName" :placeholder="`请输入账号名称`" />
				</a-form-item>
				<a-form-item
					name="password"
					:rules="[
						{
							required: true,
							message: `请输入账号密码`,
							trigger: 'blur',
						},
					]"
					:label="`账号密码`"
				>
					<a-input type="password" v-model:value="state.ruleForm.password" :placeholder="`请输入账号密码`" />
				</a-form-item>
				<a-form-item :wrapper-col="{ offset: 8, span: 16 }">
					<a-button type="primary" html-type="submit" :loading="loading">登 录</a-button>
				</a-form-item>
			</a-form>
		</div>
	</div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue"
import { useStore } from "vuex"
import { useRoute } from "vue-router"
import { localSet } from "@/common/utils"
import axios from "@/common/axios"
import api from "@/common/api/api"
import sha512 from "js-sha512"
import { message } from "ant-design-vue"
import { setCookie } from "@/common/utils/cookie"
import { getSingleSignOnURL } from "@/common"
const store = useStore()
const route = useRoute()
const loading = ref(false)
const singleSignOnURL = getSingleSignOnURL()
const state = reactive({
	ruleForm: {
		merchantName: "",
		userName: "",
		password: "",
	},
	rules: {
		merchantName: [
			{
				required: true,
				message: "请输入商户",
				trigger: "blur",
			},
		],
		userName: [
			{
				required: true,
				message: "请输入账户",
				trigger: "blur",
			},
		],
		password: [
			{
				required: true,
				message: "请输入密码",
				trigger: "blur",
			},
		],
	},
	search: {
		type: "",
		merchantName: "",
		userName: "",
		password: "",
	},
	isTrue: false,
	labelCol: { span: 6 },
	wrapperCol: { span: 16 },
})
// const { proxy } = getCurrentInstance()
// const router = proxy.$root.$router
onMounted(() => {
	console.log("route.query", route.query)
	setCookie({ reportPlatform: "idaas" })
	let stringData = window.location.search && window.location.search.split("?")[1]
	let type = stringData && stringData.split("AND")[0]
	let userName = stringData && stringData.split("AND")[1]
	let password = stringData && stringData.split("AND")[2]
	let merchantName = stringData && stringData.split("AND")[3]
	state.search = { type, userName, password, merchantName }
	// console.log(state.search)
	if (type && type == "password") {
		state.ruleForm.userName = state.search.userName
		state.ruleForm.password = state.search.password
		state.ruleForm.merchantName = state.search.merchantName
		submitForm(type)
	} else if (type && type == "merchantName") {
		state.ruleForm.merchantName = state.search.userName
	}
})
const submitForm = async (type) => {
	const data = {
		userName: state.ruleForm.userName || null,
		merchantName: state.ruleForm.merchantName || null,
		password: state.ruleForm.password || null,
	}
	if (type !== "password") {
		let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@_#.$%^&*])[A-Za-z\d~!@_#.$%^&*]{8,20}$/
		state.isTrue = reg.test(data.password)
		// if (!isTrue) {
		//   // state.search.type = "password";
		//   message.warning("当前密码强度过低，请到系统设置页面修改密码");
		// }
		data.password = sha512(data.password)
	}
	loading.value = true
	data.authSysCode = "IDAAS"
	// 表单校验有问题
	// TODO需要也走单点登录
	axios
		.post(api.login, data)
		.then(async (res) => {
			// console.log(res)
			if (res) {
				setCookie({ d_session_id: res.user?.sessionId })
				if (res.user.originalMerchantCode === "GEEZ") {
					await getDataList()
				}
				localSet("token", res)
				localSet("appInfoAlert", "open")
				localSet("userAccount", res.user.userName)
				store.dispatch("user/LOGIN", res)
				if (!state.isTrue) {
					// state.search.type = "password";
					message.warning("The current password strength is too low, please go to the system settings page to change the password")
				}
				window.location.href = singleSignOnURL
				loading.value = false
			}
		})
		.catch(() => {
			loading.value = false
		})
}

const getDataList = () => {
	const data = {
		page_no: 1,
		page_size: 1000,
		status: true,
	}
	axios
		.post(api.getMerchantList, data)
		.then((res) => {
			localSet("merchantList", res.list)
		})
		.catch((err) => {
			console.log(err)
		})
}
</script>
<style lang="scss" scoped>
.login-body {
	width: 100%;
	height: 100vh;
	background-color: #090723;
	overflow: hidden;

	.login-container {
		width: 350px;
		height: auto;
		border: 1px solid grey;
		padding: 30px;
		margin: 100px auto;
		background-color: white;
	}

	.head {
		text-align: center;
		font-size: 25px;
		font-weight: 900;
		line-height: 60px;
		margin-bottom: 20px;
	}
}

.ant-form {
	:deep(.ant-form-item) {
		width: 100%;
		margin-bottom: 0;
		display: flex;
		justify-content: space-between;
		height: 56px;
		margin-right: 0;
		padding-right: 10px;
	}
}

.ant-form {
	:deep(.ant-form-item-with-help) {
		margin-bottom: 0;
	}
}

.ant-form {
	:deep(.ant-form-item-control-input-content) {
		display: flex;
	}
}
</style>
