import { hostURL } from "@/config/config"
import HostUrl from "../utils/HostUrl.js"

let hostUrl = new HostUrl()

let host = hostUrl.getUrl(""),
	shop_api = hostUrl.getUrl("SHOP"),
	fms_api = hostUrl.getUrl("FMS")
let idaas_api = hostURL
if (process.env.NODE_ENV == "development") {
	idaas_api = "/idaas_api"
} else {
	idaas_api = hostURL + "api"
}
export default {
	authorizeOauth: host + "/oauth/authorize", //单点登录解析

	login: idaas_api + "/login",
	loginOut: idaas_api + "/logout",
	dashboardList: idaas_api + "/merchant/dashboard",
	menuList: idaas_api + "/resource/menu", //菜单
	dingtalk: idaas_api + "/open/oauth/renderV2/dingtalk", // 钉钉二维码
	callbackDing: idaas_api + "/open/oauth/callbackV2/dingtalk", //钉钉二维码
	registerMerchant: idaas_api + "/merchant/register", //注册商户
	queryRegisterProgress: idaas_api + "/merchant/queryRegisterProgress", //查询商户注册进度
	sendResetPasswordMessage: idaas_api + "/user/sendResetPasswordMessage", //重置密码
	getCounrtyList: idaas_api + "/region/countryList", //国家列表
	getBaseUrl: idaas_api + "/app/getBaseUrl",
	// 机构管理
	departmentList: idaas_api + "/department/list",
	departmentSave: idaas_api + "/department/save",
	departmentInfo: idaas_api + "/department/getById",
	departmentAddUsers: idaas_api + "/department/addUsers",
	departmentDelUsers: idaas_api + "/department/removeUsers",
	departmentAddApps: idaas_api + "/department/addMerchantApps",
	departmentDelApps: idaas_api + "/department/removeMerchantApps",
	departmentAddShops: idaas_api + "/department/addShops", //业务组添加店铺
	departmentGetRoot: idaas_api + "/department/getRoot", //获取节点
	getDepartmentListAttr: idaas_api + "/department/listAttr",

	// 权限管理
	authoritySystemList: idaas_api + "/authoritySystem/list",
	authoritySystemSave: idaas_api + "/authoritySystem/save",
	// 应用授权
	appUserAuth: idaas_api + "/appUser/auth",
	appUserList: idaas_api + "/appUser/list",

	// 资源管理
	resourceList: idaas_api + "/resource/list",
	resourceSave: idaas_api + "/resource/save",
	resourceDelete: idaas_api + "/resource/delete",
	resourceSort: idaas_api + "/resource/sort",
	userSaveUserAuth: idaas_api + "/user/saveUserAuth",
	getResourceMap: idaas_api + "/resource/menuFuncMappingList",
	// 角色管理
	roleList: idaas_api + "/role/list",
	roleSave: idaas_api + "/role/save",
	roleDetail: idaas_api + "/role/detail",
	deleteRole: idaas_api + "/role/delete",

	// 商户应用
	getMerchantAppList: idaas_api + "/merchantApp/page",
	getMerchantAppDetail: idaas_api + "/merchantApp/detail",
	saveMerchantApp: idaas_api + "/merchantApp/save",
	getAppList: idaas_api + "/app/page", //应用列表
	getAppPlanList: idaas_api + "/appPlan/list",
	fileUpload: idaas_api + "/file/upload",
	appBindInfo: idaas_api + "/merchantApp/queryBindInfo",
	merchantAppListBindabl: idaas_api + "/merchantApp/listBindable",
	merchantAppBind: idaas_api + "/merchantApp/bind",
	appGetAuthUrl: idaas_api + "/app/getAuthUrl",
	getAccessInfo: idaas_api + "/merchantApp/getAccessToken",
	appAddList: idaas_api + "/merchantApp/toAddList", //查询OMS可添加店铺列表
	reAuthApp: idaas_api + "/merchantApp/reAuth", //LAZada shopedd 重新授权
	createProduct: idaas_api + "/merchantApp/createProduct", //创建产品
	queryProductCreateProgress: idaas_api + "/merchantApp/queryProductCreateProgress", //查询创建产品进度
	appCategoryList: idaas_api + "/app/categoryList", //app类型
	appSaveTemplate: idaas_api + "/app/save", //app新增应用类型
	getSubAppList: idaas_api + "/app/listSubApp", //子应用

	//供应商管理
	supplierList: idaas_api + "/supplier/list",

	//货主管理
	wmsShipperList: idaas_api + "/wmsShipper/list",
	wmsShipperSave: idaas_api + "/wmsShipper/save",
	mappingWarehouse: idaas_api + "/wmsShipper/mappingWarehouse",
	//仓库管理
	wmsWarehouseList: idaas_api + "/wmsWarehouse/list",
	wmsWarehouseSave: idaas_api + "/wmsWarehouse/save",
	wmsWarehouseSync: idaas_api + "/wmsWarehouse/syncFrom",
	omsWarehouseList: idaas_api + "/omsWarehouse/list",
	omsWarehouseSave: idaas_api + "/omsWarehouse/save",
	//店铺管理
	batchAddShop: idaas_api + "/omsShop/batchAdd", //OMS应用添加店铺
	omsShopList: idaas_api + "/omsShop/list",
	omsShopSave: idaas_api + "/omsShop/save",
	omsShopSync: idaas_api + "/omsShop/sync", //同步店铺到第三方平台
	wmsShopList: idaas_api + "/wmsShop/list",
	wmsShopSave: idaas_api + "/wmsShop/save",
	wmsShopSyncFrom: idaas_api + "/wmsShop/syncForm", //同步店铺到第三方平台
	wmsShopSync: idaas_api + "/wmsShop/syncTo", //同步店铺到第三方平台

	// OMS店铺配置
	omsSetWarehouse: idaas_api + "/omsShop/config/listWarehouse", //OMS仓库列表
	omsSetWarehouseCodeList: idaas_api + "/omsShop/config/listWarehouseToAdd", //WMS仓库列表
	omsSetWarehouseSave: idaas_api + "/omsShop/config/saveWarehouse", //OMS仓库保存

	omsSetShipper: idaas_api + "/omsShop/config/listShipper", //oms货主列表
	omsSetShipperSave: idaas_api + "/omsShop/config/saveShipper", //oms货主保存
	omsSetShopSave: idaas_api + "/omsShop/config/saveShipperShop", //oms货主店铺保存

	omsSetShipping: idaas_api + "/omsShop/config/listShipping", //oms快递列表
	omsSetShippingSave: idaas_api + "/omsShop/config/saveShipping", //oms快递保存
	omsSetShippingCodeList: idaas_api + "/omsShop/config/listShippingToAdd", //oms快递Code列表

	omsSetAccountSave: idaas_api + "/omsShop/config/saveTmsAccount", //oms快递账号保存

	omsShopSet: idaas_api + "/omsShop/config/shopWarehouseShippingMapping", //配置店铺

	omsShopSetInfo: idaas_api + "/omsShop/config/detail", //店铺详情

	// WMS店铺配置
	wmsSetWarehouse: idaas_api + "/wmsShop/config/listWarehouse", //仓库列表
	wmsSetWarehouseSave: idaas_api + "/wmsShop/config/saveWarehouse", //仓库保存

	wmsSetShipping: idaas_api + "/wmsShop/config/listShipping", //wms快递列表
	wmsSetShippingSave: idaas_api + "/wmsShop/config/saveShipping", //wms快递保存
	wmsSetShippingCodeList: idaas_api + "/wmsShop/config/listShippingToAdd", //tms快递列表

	wmsSetAccountSave: idaas_api + "/wmsShop/config/saveTmsAccount", //wms快递账号保存

	wmsShopSet: idaas_api + "/wmsShop/config/shopWarehouseShippingMapping", //配置店铺

	wmsShopSetInfo: idaas_api + "/wmsShop/config/detail", //店铺详情
	wmsShipperAsync: idaas_api + "/wmsShipper/getAndSave", //店铺详情
	wmsShipperWarehouseAsync: idaas_api + "/wmsShipper/getAndSaveWarehouse", //店铺详情

	// 快递管理
	expressList: idaas_api + "/express/getOne", //快递列表
	omsShippingList: idaas_api + "/omsShipping/list", //oms快递列表
	omsShippingSave: idaas_api + "/omsShipping/save", //oms快递保存
	wmsShippingList: idaas_api + "/wmsShipping/list", //wms快递列表
	wmsShippingSave: idaas_api + "/wmsShipping/save", //wms快递保存
	tmsShippingList: idaas_api + "/tmsShipping/list", //tms快递列表
	tmsShippingSave: idaas_api + "/tmsShipping/Save", //tms快递列表
	//快递账号列表
	tmsAccountList: idaas_api + "/tmsAccount/list", //账号列表
	tmsAccountSave: idaas_api + "/tmsAccount/save", //账号列表
	tmsAccountAuth: idaas_api + "/tmsAccount/auth", //授权账号
	// 商户接口
	getMerchantList: idaas_api + "/merchant/page",
	saveMerchant: idaas_api + "/merchant/save",
	// 账户接口
	switchMerchant: idaas_api + "/user/switchMerchant", //切商户
	getUserList: idaas_api + "/user/page",
	saveUser: idaas_api + "/user/saveUser",
	configDetail: idaas_api + "/user/configDetail",
	saveConfig: idaas_api + "/user/saveConfig",
	userInfo: idaas_api + "/user/detail",
	resetPassword: idaas_api + "/user/resetPassword",
	// 店铺账号
	saveAccount: idaas_api + "/omsShop/saveAccount",
	getAccount: idaas_api + "/omsShop/getAccount",
	//生成密钥
	saveAppClient: idaas_api + "/appClient/save",
	//校验用户名重复接口
	isNameHave: idaas_api + "/youTool/checkNameExist",
	//系统设置
	changePassword: idaas_api + "/user/updatePassword",
	//获取国家列表
	countryList: idaas_api + "/region/countryList",
	//获取地区列表
	regionList: idaas_api + "/region/list",

	getListTrade: idaas_api + "/merchant/listTrade", //行业列表

	getAppTypeList: idaas_api + "/app/appTypeList", //应用类型列表
	getServiceTypeList: idaas_api + "/app/serviceTypeList", //服务类型列表
	// message
	getSystemMessageList: idaas_api + "/systemMessage/list", //systemMessage
	createSystemMessage: idaas_api + "/systemMessage/create", //systemMessage
	updateSystemMessage: idaas_api + "/systemMessage/update", //systemMessage
	changeSystemMessageStatus: idaas_api + "/systemMessage/changeStatus", //systemMessage

	queryWarehousePage: shop_api + "/warehouse/queryWarehousePage", //shop服务仓库列表接口
	queryCurrencyPage: fms_api + "/baseInfo/currencyPage", //货币
}
