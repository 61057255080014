<template>
	<div class="login-page">
		<div class="login-content">
			<div class="logo-icon">
				<div class="outer2" />
			</div>
			<div class="main-content">
				<div class="left-image" />
				<div class="login-input-content" @keydown.enter="submitForm">
					<div class="login-title">账号登录</div>
					<a-row style="margin-top: 20%">
						<a-input v-model:value="ruleForm.merchantName" placeholder="商家名称" class="border-show" />
					</a-row>
					<a-row style="margin-top: 20%">
						<a-input v-model:value="ruleForm.userName" placeholder="用户名" class="border-show" />
					</a-row>
					<a-row style="margin-top: 20%">
						<a-input-password v-model:value="ruleForm.password" placeholder="密码" class="border-show" />
					</a-row>
					<a-row id="error-msg" style="text-align: center; margin: 15px 0; color: red; font-size: 14px">
						<span v-text="errorMsg" />
					</a-row>
					<a-button class="login-btn" type="primary" shape="round" :loading="loading" @click="submitForm"> 登 录 </a-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, defineEmits } from "vue"
import axios from "@/common/axios"
import api from "@/common/api/api"
import sha512 from "js-sha512"
import { debounce } from "lodash"
import { message } from "ant-design-vue"
const state = ref({
	labelCol: { span: 6 },
	wrapperCol: { span: 16 },
	checked: true,
	rules: {
		merchantName: [
			{
				required: true,
				message: "请输入商家名称",
				trigger: "blur",
			},
		],
		userName: [
			{
				required: true,
				message: "请输入用户名",
				trigger: "blur",
			},
		],
		password: [
			{
				required: true,
				message: "请输入密码",
				trigger: "blur",
			},
		],
	},
	search: {
		type: "",
		userName: "",
		password: "",
	},
	isTrue: false,
})

const emits = defineEmits(["login"])
const errorMsg = ref("")
const ruleForm = ref({
	merchantName: "",
	userName: "",
	password: "",
})
const loading = ref(false)
const submitForm = debounce(async (type) => {
	if (loading.value) return
	const data = {
		userName: ruleForm.value.userName,
		merchantName: ruleForm.value.merchantName,
		password: ruleForm.value.password,
	}
	if (!ruleForm.value.merchantName) {
		errorMsg.value = "请输入商家名称"
	} else if (!ruleForm.value.userName) {
		errorMsg.value = "请输入用户名"
	} else if (!ruleForm.value.password) {
		errorMsg.value = "请输入密码"
	} else {
		loading.value = true
		errorMsg.value = ""
		if (type !== "password") {
			let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@_#.$%^&*])[A-Za-z\d~!@_#.$%^&*]{8,20}$/
			state.value.isTrue = reg.test(data.password)
			data.password = sha512(data.password)
		}
    data.authSysCode = "ULTRA_WMS"
		axios
			.post(api.login, data)
			.then((res) => {
				if (res && res.user) {
					emits("login", res)
				}
			})
			.catch((err) => {
				loading.value = false
				message.error(err.msg)
			})
	}
}, 500)
</script>
<style lang="scss" scoped>
.login-body {
	width: 100%;
	height: 100vh;
	background-color: #090723;
	overflow: hidden;
	.login-container {
		width: 300px;
		height: auto;
		border: 1px solid grey;
		padding: 30px;
		margin: 100px auto;
		background-color: white;
	}
	.head {
		text-align: center;
		font-size: 25px;
		font-weight: 900;
		line-height: 60px;
		margin-bottom: 20px;
	}
}
.ant-form {
	::v-deep(.ant-form-item-with-help) {
		margin-bottom: 0;
	}
}
.ant-form {
	::v-deep(.ant-form-item-control-input-content) {
		display: flex;
	}
}
.ant-form {
	::v-deep(.ant-form-item) {
		width: 100%;
		margin-bottom: 0;
		display: flex;
		justify-content: space-between;
		height: 56px;
		margin-right: 0;
		padding-right: 10px;
	}
}
.login-page {
	background-color: rgba(255, 255, 255, 1);
	position: relative;
	width: 100%;
	min-height: 100%;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: auto;
	padding: 30px 0;
	&::before {
		content: url("../../assets/img/background_rt.png");
		position: absolute;
		top: 0;
		right: 0;
	}
	&::after {
		content: url("../../assets/img/background_bottom.png");
		position: absolute;
		bottom: 0;
		left: 0;
	}
	.login-content {
		box-shadow: 10px 15px 17px -5px rgba(0, 0, 0, 0.18);
		background-color: rgba(255, 255, 255, 1);
		border-radius: 50px;
		padding: 0 80px 120px;
		position: relative;
		z-index: 10;
		.logo-icon {
			display: flex;
			align-items: center;
			margin-bottom: 50px;
			margin-top: -30px;
			.outer2 {
				width: 74px;
				height: 89px;
				background: url("../../assets/img/background_poker.png") no-repeat;
				margin-right: 20px;
			}
			.word1 {
				overflow-wrap: break-word;
				color: rgba(0, 136, 255, 1);
				font-size: 36px;
				font-family: PingFangSC-Medium;
				white-space: nowrap;
				margin-top: 10px;
			}
		}
		.main-content {
			display: flex;
			width: 100%;
			.left-image {
				width: 620px;
				height: 451px;
				background: url("../../assets/img/background_main.png") 20% no-repeat;
				background-size: 100% 100%;
				margin-right: 50px;
			}
			.login-btn {
				display: inline-block;
				width: 300px;
				height: 50px;
				font-size: 24px;
			}
			.text-group {
				margin: 25px 0 0 2px;
				display: flex;
				align-items: center;
				.ding-login-btn {
					margin-left: 20px;
				}
			}
			.ding-login-content {
				width: 300px;
				font-size: 16px;
				#dingding-login-container {
					min-height: 300px;
				}
				.flex-colunm {
					display: flex;
					flex-direction: column;
					align-items: center;
					margin-top: 20px;
					.text-tips {
						margin: 16px 0 30px;
					}
				}
			}
		}
	}
	.login-title {
		width: 118px;
		height: 40px;
		overflow-wrap: break-word;
		color: rgba(0, 0, 0, 1);
		font-size: 28px;
		font-family: PingFang SC-ExtraLight, PingFang SC;
		text-align: right;
		white-space: nowrap;
		line-height: 40px;
		display: block;
		margin-bottom: 30px;
	}
}

.login-input-content {
	::v-deep(.border-show) {
		font-size: 18px;
		font-family: PingFangSC-Regular;
		border-top: none;
		border-right: none;
		border-left: none;
		outline: none;
		border-radius: 0;
		box-shadow: none;
	}
}

input:focus {
	outline: 0px solid #fff !important;
}

.user-block {
	width: 100%;
	margin-top: 20px;
	height: 50px;
	box-shadow: 0px 0px 10px 0px #e6f4ff;
	border-radius: 10px;
	border: 1px solid #e2e2e2;
	display: flex;
	align-items: center;
	padding-left: 16px;
	cursor: pointer;
	&.active {
		background: rgba(0, 136, 255, 0.1);
		border-color: #0088ff;
	}
}

.ding-select-btn {
	margin-top: 30px;
}
input:-webkit-autofill {
	font-size: 18px;
	transition: background-color 50000s ease-in-out 0s;
}
.lang-select {
	position: fixed;
	top: 10px;
	right: 10px;
}
</style>
