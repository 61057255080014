export function localGet(key) {
	const value = window.localStorage.getItem(key)
	try {
		return JSON.parse(window.localStorage.getItem(key))
	} catch (error) {
		return value
	}
}

export function localSet(key, value) {
	window.localStorage.setItem(key, JSON.stringify(value))
}

export function localRemove(key) {
	window.localStorage.removeItem(key)
}

export function getSingleSignOnURL() {
	let origin = window.location.origin
	const singleSignOnURL =
		origin +
		"/api/open/oauth/authorize?app_key=spade_gateway_001&response_type=code&scope=IDAAS&terminal=PC&redirect_uri=" +
		origin +
		"&idaas_uri=" +
		origin
	if (process.env.NODE_ENV == "development") {
		return "http://localhost:3094/idaas_api/open/oauth/authorize?app_key=spade_gateway_001&response_type=code&scope=IDAAS&terminal=PC&redirect_uri=http://localhost:3094&idaas_uri=http://localhost:3094"
	} else {
		return singleSignOnURL
	}
}
