<template>
	<div class="login-container" v-if="show && from">
		<IdaasLogin v-if="from === 'IDAAS'" @login="goToOauth" />
		<SrmLogin v-else-if="from === 'SRM'" @login="goToOauth" />
		<WmsLogin v-else-if="from === 'ULTRA_WMS'" @login="goToOauth" />
		<OmsLogin :from="from" v-else @login="goToOauth" />
	</div>
	<div class="flex-c-c" v-else>
		<img class="loading" src="@/assets/img/loading.gif" alt="" />
	</div>
</template>

<script setup>
import { onMounted, ref } from "vue"
import { useRoute, useRouter } from "vue-router"
import { useStore } from "vuex"
import OmsLogin from "./omsLogin.vue"
import WmsLogin from "./wmsLogin.vue"
import IdaasLogin from "./idaasLogin.vue"
import SrmLogin from "./srmLogin.vue"
import { setCookie } from "@/common/utils/cookie"
import { localSet } from "@/common/utils"
import axios from "@/common/axios"
import api from "@/common/api/api"
import { getSingleSignOnURL } from "@/common"

const singleSignOnURL = getSingleSignOnURL()
const router = useRouter()
const store = useStore()
const route = useRoute()
const show = ref(true)
const from = ref("")
onMounted(() => {
	show.value = true
	from.value = route.query.scope
	if (route.query.gateway_uri) {
		show.value = false
		localStorage.setItem("apiURL", route.query.gateway_uri)
	}
	if (route.query.code) {
		oauthLogin(route.query.code)
		return
	}
	// if (route.query.scope === "IDAAS" && route.query.app_key) {
	//   show.value = false
	// }

	if (!from.value && !route.query.code) {
		window.location.href = singleSignOnURL
	}
})
// const isOMS = computed(() => ["OMS", "FMS", "ERP", "ULTRA_BI", "FAST_BOOKS", "RENTAL_APP"].includes(from.value))
const goToOauth = async (res) => {
	setCookie({ d_session_id: res.user?.sessionId })

	if (res.user.originalMerchantCode === "GEEZ") {
		await getDataList()
	}
	localSet("token", res)
	localSet("appInfoAlert", "open")
	localSet("userAccount", res.user.userName)
	store.dispatch("user/LOGIN", res)

	window.location.href =
		window.location.origin +
		`/api/open/oauth/authorize?app_key=${route.query.app_key}&response_type=${route.query.response_type}&state=${route.query.state}&scope=${route.query.scope}&terminal=PC&redirect_uri=${route.query.g}&&idaas_uri=${window.location.origin}`
}

const getDataList = () => {
	const data = {
		page_no: 1,
		page_size: 1000,
		status: true,
	}
	axios
		.post(api.getMerchantList, data)
		.then((res) => {
			localSet("merchantList", res.list)
		})
		.catch((err) => {
			console.log(err)
		})
}
const oauthLogin = (code) => {
	axios
		.post(api.authorizeOauth, {
			authCode: code,
		})
		.then((res) => {
			setCookie({ Authorization: res })
			setTimeout(() => {
				jump()
			}, 100)
		})
		.catch((err) => {
			console.log(err)
			window.location.href = singleSignOnURL
		})
}

const jump = () => {
	router.push("/overview")
}
</script>

<style scoped>
.login-container {
	height: 100vh;
}

.flex-c-c {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	.loading {
		width: 100px;
		height: 100px;
		display: block;
	}
}
</style>
